import React, { useState, useEffect } from "react";
import { connect } from "react-redux";

import PropTypes from "prop-types";
import EventBusPublisher from "../event-bus-publisher";
import { parseOnlyNumbers } from "../../util/strings";
import useDeviceType from "../../util/useDeviceType";

export function NewspaperMidline(props) {
  const volume = props.data.volume;

  const router = props.router;
  const page = props.page;
  const pages = props.pageData;
  const isFixed = props.fixed;
  const isArticleView = props.isArticleView;
  const { isMobile } = useDeviceType();
  const { onLoadingStart, showMusicNews, showWorldNews } = props;
  const [showMusicNewsClicked, setShowMusicNewsClicked] = useState(true);
  const [showWorldNewsClicked, setShowWorldNewsClicked] = useState(false);
  const maxPage = pages.length;
  const maxMusicPage = props.musicPageData.length + 1;
  const maxWorldPage = props.worldPageData.length + 1;
  const pageIndex = pages.findIndex((thePage) => {
    return thePage.order === page;
  });
  const musicPageIndex = props.musicPageData.findIndex((thePage, index) => {
    return thePage.order === page ? index + 2 : null;
  });
  const worldPageIndex = props.worldPageData.findIndex((thePage, index) => {
    return thePage.order === page ? index + 2 : null;
  });

  const canGoBack = () => {
    if (!pages || pageIndex === -1) {
      // Return false or handle the case where pages is not loaded
      return false;
    }

    return pages[pageIndex].category === "music"
      ? musicPageIndex + 2 > 1
      : worldPageIndex + 2 > 1;
  };

  const canGoForward = () => {
    if (!pages || pageIndex === -1) {
      // Return false or handle the case where pages is not loaded
      return false;
    }
    return pages[pageIndex].category === "music"
      ? musicPageIndex + 1 < maxMusicPage - 1
      : worldPageIndex + 1 < maxWorldPage - 1;
  };

  const goToPreviousPageInHistory = () => {
    window.history.back();
  };

  const goBack = () => {
    onLoadingStart();
    let _prevPageIndex = pageIndex - 1;
    if (showWorldNewsClicked && worldPageIndex === 0) {
      _prevPageIndex = 0;
    } else {
      _prevPageIndex = pageIndex - 1;
    }
    const _pageTitle = pages[_prevPageIndex].title;
    const _prevPageNumber = _pageTitle.includes("page")
      ? parseOnlyNumbers(_pageTitle)
      : _pageTitle || 1;
    pages.map((item, index) => {
      if (item.title === `page-${_prevPageNumber}`) {
        if (item.category === "music") {
          onClickMusic();
        }
        if (item.category === "world") {
          onClickNews();
        }
      }
    });
    router.push(`/times-contrarian/${_prevPageNumber}`);
    EventBusPublisher.changePage(_prevPageNumber);
  };

  const goForward = () => {
    onLoadingStart();
    let _nextPageIndex = pageIndex + 1;
    if (showWorldNewsClicked && pageIndex === 0) {
      _nextPageIndex = maxMusicPage;
    } else {
      _nextPageIndex = pageIndex + 1;
    }

    const _pageTitle = pages[_nextPageIndex].title;
    const _nextPageNumber = _pageTitle.includes("page")
      ? parseOnlyNumbers(_pageTitle)
      : _pageTitle || 1;
    pages.map((item, index) => {
      if (item.title === `page-${_nextPageNumber}`) {
        if (item.category === "music") {
          onClickMusic();
        }
        if (item.category === "world") {
          onClickNews();
        }
      }
    });
    router.push(`/times-contrarian/${_nextPageNumber}`);
    EventBusPublisher.changePage(_nextPageNumber);
  };

  const onClickMusic = () => {
    showMusicNews();
    setShowMusicNewsClicked(true);
    setShowWorldNewsClicked(false);
    pages.map((item, index) => {
      console.log(item);
      if (item.showInBoth && item.defaultPageOnCategory) {
        const pageNumber = item.title.split("-")[1];
        router.push(`/times-contrarian/${pageNumber}`);
        EventBusPublisher.changePage(pageNumber);
      }
    });
  };

  const onClickNews = () => {
    showWorldNews();
    setShowMusicNewsClicked(false);
    setShowWorldNewsClicked(true);

    pages.map((item, index) => {
      console.log(item);
      if (item.category === "world" && item.defaultPageOnCategory) {
        const pageNumber = item.title.split("-")[1];
        router.push(`/times-contrarian/${pageNumber}`);
        EventBusPublisher.changePage(pageNumber);
      }
    });
  };

  useEffect(() => {
    pages.map((item, index) => {
      if (item.title === `page-${page}`) {
        if (item.category === "music") {
          showMusicNews();
          setShowMusicNewsClicked(true);
          setShowWorldNewsClicked(false);
        }
        if (item.category === "world") {
          showWorldNews();
          setShowMusicNewsClicked(false);
          setShowWorldNewsClicked(true);
        }
      }
    });
  }, [pages]);

  return (
    <div className={`middleline line ${isFixed ? "fixed" : ""}`}>
      <div
        className={`swipe-button left ${
          canGoBack() || isArticleView ? "visible" : "invisible"
        }`}
        onClick={() => {
          isArticleView && isMobile ? goToPreviousPageInHistory() : goBack();
        }}
      ></div>
      <div className="left-side"></div>
      <div className="center">
        <div className="box">
          <div
            className={`${showMusicNewsClicked ? "button-clicked" : "button"}`}
            onClick={onClickMusic}
          >
            Music News
          </div>
        </div>
        <div className="box">
          <div
            className={`${showWorldNewsClicked ? "button-clicked" : "button"}`}
            onClick={onClickNews}
          >
            World News
          </div>
        </div>
      </div>
      <div className="right-side">
        <div className="box mobile-hidden">
          {canGoBack() ? (
            <div className="button" onClick={goBack}>
              PREVIOUS
            </div>
          ) : null}
        </div>
        {showMusicNewsClicked && (
          <div className="box index">
            {musicPageIndex + 2} of {maxMusicPage}
          </div>
        )}
        {showWorldNewsClicked && (
          <div className="box index">
            {worldPageIndex + 2} of {maxWorldPage}
          </div>
        )}
        <div className="box mobile-hidden">
          {canGoForward() ? (
            <div className="button" onClick={goForward}>
              NEXT
            </div>
          ) : null}
        </div>
      </div>
      {isArticleView && isMobile ? null : (
        <div
          className={`swipe-button right ${
            canGoForward() ? "visible" : "invisible"
          }`}
          onClick={() => {
            goForward();
          }}
        ></div>
      )}
    </div>
  );
}

NewspaperMidline.contextTypes = {
  pageData: PropTypes.object,
  router: PropTypes.object,
  page: PropTypes.number,
};

const mapStateToProps = (state) => {
  let pageData = state?.contrarianPage?.all || [];
  pageData = pageData.sort((a, b) => a.order - b.order);
  let musicPageData = pageData.filter((item) => item.category === "music");
  let worldPageData = pageData.filter((item) => item.category === "world");
  return {
    data: state?.newsPage?.page || "",
    pageData: pageData,
    musicPageData: musicPageData,
    worldPageData: worldPageData,
  };
};

export default connect(mapStateToProps, {})(NewspaperMidline);
